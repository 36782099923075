import * as React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import * as s from './AsideCert.module.scss';

export interface AsideCertProps {
  href: string;
  img: string;
  certProvider: string;
  certName: string;
}

const AsideCert = ({ href, img, certProvider, certName }: AsideCertProps) => (
  <OutboundLink className={s.link} href={href} rel="noreferrer nofollow" target="_blank">
    <img src={img} className={s.img} width={64} height={64} alt={certName} title={certName} />
    <span className={s.text}>
      <b>{certProvider}</b>
      <br />
      {certName}
    </span>
  </OutboundLink>
);

export default AsideCert;
