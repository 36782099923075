import * as React from 'react';
import classNames from 'classnames';
import { I18nextContext, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { I18NextContext } from 'gatsby-plugin-react-i18next/dist/types';
import * as s from './Aside.module.scss';
import AsideHeader from '../AsideHeader/AsideHeader';
import AsideTable from '../AsideTable/AsideTable';
import AsideSocial from '../AsideSocial/AsideSocial';
import imgMwsCert from '../../../../images/mws.png';
import imgMsArchitectCert from '../../../../images/ms-architect-badge.svg';
import imgMsDeveloperCert from '../../../../images/ms-developer-badge.svg';
import imgDownload from '../../../../images/download.svg';
import { linkedinProfile } from '../../../../data/urls';
import AsideSection from '../AsideSection/AsideSection';
import AsideCert from '../AsideCert/AsideCert';

const Aside = () => {
  const { t } = useTranslation();
  const context = React.useContext<I18NextContext>(I18nextContext);

  return (
    <aside className={s.root}>
      <AsideHeader />

      <p className={s.description}>
        <Trans>aside.description</Trans>
      </p>

      <AsideSection title={t('aside.certifications.title')}>
        <AsideCert
          href="https://learn.microsoft.com/en-gb/users/omarmuscatello-1960/credentials/fbac466ff1798ba7"
          img={imgMsArchitectCert}
          certProvider="Microsoft Azure"
          certName="Solutions Architect Expert Certification"
        />

        <AsideCert
          href="https://www.credly.com/badges/2ec492dc-f5bf-4c86-9a32-ccf81cf0fec9"
          img={imgMsDeveloperCert}
          certProvider="Microsoft Azure"
          certName="Developer Associate Certification"
        />

        <AsideCert
          href="https://developers.google.com/training/certification/"
          img={imgMwsCert}
          certProvider="Google"
          certName="Certified Mobile Web Specialist"
        />

        <p className={s.otherCerts}>
          <Trans>aside.certifications.others</Trans>
          <OutboundLink href={linkedinProfile} rel="noreferrer nofollow" target="_blank">
            <Trans>aside.certifications.othersLink</Trans>
          </OutboundLink>
          .
        </p>
      </AsideSection>

      <AsideSection title={t('aside.info.title')}>
        <AsideTable
          items={[
            {
              label: t('aside.info.nameAndSurname'),
              value: 'Omar Muscatello',
            },
            {
              label: t('aside.info.dateOfBirth'),
              value: t('aside.info.dateOfBirthVal'),
            },
            {
              label: t('aside.info.nationality'),
              value: t('aside.info.nationalityVal'),
            },
            {
              label: t('aside.info.languages'),
              value: t('aside.info.languagesVal'),
              showBorder: false,
            },
          ]}
        />
      </AsideSection>

      <AsideSection title={t('aside.contacts.title')}>
        <AsideTable
          items={[
            {
              label: t('aside.contacts.mobile'),
              value: '+39 340 904 9957',
              link: 'tel:+393409049957',
            },
            {
              label: 'Email',
              value: 'omar.muscatello@gmail.com',
              link: 'mailto:omar.muscatello@gmail.com',
            },
            {
              label: t('aside.contacts.location'),
              value: t('aside.contacts.locationVal'),
            },
          ]}
        />
      </AsideSection>

      <OutboundLink
        className={classNames([s.btnDownload, 'no-print'])}
        href={`/download/Omar_Muscatello_Resume_${context.language.toUpperCase()}_v11.pdf`}
      >
        <img src={imgDownload} alt="" aria-hidden height={16} width={24} />
        <Trans>aside.downloadPdf</Trans>
      </OutboundLink>

      <AsideSocial />
    </aside>
  );
};

export default Aside;
